import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Ad } from '@dmm/react-common-components';
import { BREAKPOINTS } from '../../utils/commonHelper';
import {
  listingPropTypes,
} from '../../utils/commonPropTypes';
import { calculateMonthlyPrice } from '../../utils/trident';
import { getCurrentLocale } from '../../utils/language';
import ListingResult from '../ListingResult';
import LeaderBoardMid from '../Ads/LeaderBoardMid';
import MobileLinerAd from '../Ads/MobileLiner';
import { setPosition } from '../../utils/paginationHelper';
import get from 'lodash/get';

import SimilarListingsMessage from '../SimilarListingsMessage';
import { injectIntl } from 'react-intl';
import { PortalConfigContext } from '../../config/portal';
import { Cookies } from 'react-cookie';
import AdSlot from '../../components/Ads/AdSlot';

import './styles.css';
import './home.css';

export const pageSizes = {
  [BREAKPOINTS.desktop]: 8,
  [BREAKPOINTS.tablet]: 6,
  [BREAKPOINTS.mobile]: 4
};

class BoatList extends React.PureComponent {
  resolveMonthlyPrice = (listing, teaserRate) => {
    const loanAmount = parseFloat(listing.price?.type?.amount?.USD || 0);
    const termInMonths = loanAmount >= 50000 ? 240 : 180;

    return calculateMonthlyPrice(teaserRate, termInMonths, loanAmount);
  };

  render() {
    const AD_POSITION_OFFSET = 2;
    const AD_POSITION = 5;
    const MAX_DESKTOP_ADS = 2;
    let itemCount = 0;
    let desktopAdCount = 0;
    let mobileAdCount = 0;
    const {
      isHome,
      showAds,
      url,
      pos,
      tracking,
      labels,
      mode,
      breakpoint,
      adParams,
      similarListings,
      similarListingsType,
      params,
      cookies,
      customUom,
      stickyLeaderboard,
      newPageAdsConfig,
      enableAdButler
    } = this.props;
    const locale = getCurrentLocale();
    const pageSize = get(
      this.context,
      'pages.searchResults.pagination.pageSize',
      28
    );
    const showBottomAdBox = get(
      this,
      'context.supports.showBottomAdBox',
      false
    );
    const altTextVariant = get(
      this.context,
      'pages.searchResults.listingImage.altText.variant',
      0
    );
    const supportsMonthlyPayment = get(
      this.context,
      `languages.${locale}.supports.monthlyPayment`,
      false
    );
    const tridentTeaserRate = get(this.props, 'tridentTeaserRate');

    return (
      <ol className={classnames('boat-list', `boat-list-${mode}`)}>
        {this.props.listings
          .map((listing, i) => {
            let listingAltTextVariant;
            if (
              mode === BoatList.MODES.home &&
              breakpoint in pageSizes &&
              i >= pageSizes[breakpoint]
            ) {
              return [];
            }

            const position = setPosition(pos, i + 1);
            const listingArr = [];
            itemCount += 1;
            switch (altTextVariant) {
              case 1:
                listingAltTextVariant = `${listing.make} ${listing.model}`;
                break;
            }
            listingArr.push(
              <ListingResult
                isHome={isHome}
                key={listing.id}
                listing={listing}
                position={position}
                url={url}
                tracking={tracking}
                labels={labels}
                breakpoint={breakpoint}
                itemCount={itemCount}
                listingType={
                  mode === BoatList.MODES.home ? 'latest listing' : ''
                }
                mode={
                  mode === BoatList.MODES.home
                    ? ListingResult.MODES.card
                    : ListingResult.MODES.row
                }
                altText={listingAltTextVariant}
                cookies={cookies}
                customUom={customUom}
                monthlyPrice={this.resolveMonthlyPrice(
                  listing,
                  tridentTeaserRate
                )}
                supportsMonthlyPayment={supportsMonthlyPayment}
                tridentTeaserRate={tridentTeaserRate}
              />
            );
            if (showAds && itemCount === AD_POSITION_OFFSET) {
              if (!enableAdButler) {
                listingArr.push(
                  <li
                    key={`deskAd${listing.id}`}
                    className={classnames({
                      'stickyLeaderboard': stickyLeaderboard
                    })}
                  >
                    <Ad {...adParams.leaderBoardAlphaParams} />
                  </li>
                );
             }
             if (enableAdButler) {
              listingArr.push(
                <li key={`deskAd${listing.id}`} className={classnames({
                  'stickyLeaderboard': stickyLeaderboard
                })}>
                  <AdSlot adParams={newPageAdsConfig} adSlot={'leaderboard-alpha'} />
                </li>
              );
             }
            } else if (
              showAds &&
              (itemCount - AD_POSITION_OFFSET) % AD_POSITION === 0 &&
              desktopAdCount < MAX_DESKTOP_ADS
            ) {
              if (!enableAdButler) {
                listingArr.push(
                  <LeaderBoardMid
                    key={`deskAd${listing.id}`}
                    adNum={++desktopAdCount}
                  />
                );
              }
              if (enableAdButler) {
                let count = ++desktopAdCount;
                listingArr.push(
                  <li key={`deskAd${listing.id}`} className="desktop-liner-ad">
                    <AdSlot adParams={newPageAdsConfig} adSlot={`leaderboard-mid-${count}`} />
                  </li>
                );
              }
            }
            if (
              showAds &&
              itemCount % AD_POSITION === 0 &&
              mobileAdCount < MAX_DESKTOP_ADS
            ) {
              if (!enableAdButler) {
                listingArr.push(
                  <MobileLinerAd
                    key={`mobAd${listing.id}`}
                    adNum={++mobileAdCount}
                  />
                );
              }
              if (enableAdButler) {
                let count = ++mobileAdCount;
                listingArr.push(<li key={`mobAd${listing.id}`} className="mobile-liner-ad premium bp1 ad-320x80 ad-inline-mobile-box">
                  <AdSlot adParams={newPageAdsConfig} adSlot={`mobile-box-${count}`} />
                </li>);
              }
            }
            return listingArr;
          })
          .reduce((prev, next) => [prev, ...next], [])}
        {similarListings && similarListings.length ? (
          <SimilarListingsMessage
            params={params}
            similarListingsType={similarListingsType}
          />
        ) : (
          <></>
        )}
        {similarListings &&
          similarListings
            .slice(
              0,
              similarListings.length + this.props.listings.length < pageSize
                ? similarListings.length
                : pageSize - this.props.listings.length
            )
            .map((listing, i) => {
              itemCount += 1;
              const position = setPosition(pos, i + 1);
              const listingArr = [
                <ListingResult
                  key={listing.id}
                  listing={listing}
                  position={position}
                  url={url}
                  tracking={tracking}
                  labels={labels}
                  itemCount={itemCount}
                  cookies={cookies}
                  monthlyPrice={this.resolveMonthlyPrice(
                    listing,
                    tridentTeaserRate
                  )}
                  supportsMonthlyPayment={supportsMonthlyPayment}
                  tridentTeaserRate={tridentTeaserRate}
                />
              ];

              return listingArr;
            })
            .reduce((prev, next) => [prev, ...next], [])}
        {showBottomAdBox && !enableAdButler && (
          <li key={'deskAdBottom'}>
            <Ad {...adParams.leaderboardBottomParams} />
          </li>
        )}
        {showBottomAdBox && enableAdButler && (
          <li key={'deskAdBottom'}>
            <AdSlot adParams={newPageAdsConfig} adSlot={'leaderboard-bottom'} />
          </li>
        )}
      </ol>
    );
  }
}

BoatList.MODES = {
  results: 'results',
  home: 'home'
};

BoatList.defaultProps = {
  mode: BoatList.MODES.results,
  similarListings: [],
  showAds: false,
  isHome: false
};

BoatList.propTypes = {
  isHome: PropTypes.bool,
  adParams: PropTypes.object,
  breakpoint: PropTypes.oneOf(Object.keys(BREAKPOINTS)),
  labels: PropTypes.object.isRequired,
  listings: PropTypes.arrayOf(listingPropTypes).isRequired,
  similarListings: PropTypes.arrayOf(listingPropTypes),
  similarListingsType: PropTypes.string,
  params: PropTypes.object,
  mode: PropTypes.oneOf(Object.keys(BoatList.MODES)),
  pos: PropTypes.shape({
    page: PropTypes.string,
    pageSize: PropTypes.string
  }).isRequired,
  tracking: PropTypes.object,
  url: PropTypes.string,
  itemCount: PropTypes.number,
  showAds: PropTypes.bool,
  showPremium: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  cookies: PropTypes.instanceOf(Cookies),
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  tridentTeaserRate: PropTypes.number,
  stickyLeaderboard: PropTypes.bool,
  newPageAdsConfig: PropTypes.object,
  enableAdButler: PropTypes.bool
};

BoatList.contextType = PortalConfigContext;

export default injectIntl(BoatList);
