import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import get from 'lodash/get';
import { withCookies, Cookies } from 'react-cookie';
import MainContent from './MainContent';
import DefaultLayout from '../../layouts/Default';
import ErrorMessage from '../../components/ErrorMessage';
import * as utils from '../../store/utils';
import * as actions from '../../store/actions';
import { getParamsFromLocationAndHistoryQueryString } from '../../utils/featureToggles';
import { runOnce } from '../../utils/runOnceHelper';
import { withABTestContext } from '../../context/ABTestContext';

const Home = (props) => {
  return (
    <DefaultLayout {...props} pageType="Home">
      {props.errors ? (
        <ErrorMessage {...props} debug={'from home'} />
      ) : (
        <MainContent {...props} />
      )}
    </DefaultLayout>
  );
};

Home.propTypes = {
  adsData: PropTypes.any,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  errors: PropTypes.bool,
  search: PropTypes.shape({
    count: PropTypes.number
  }).isRequired,
  seoContent: PropTypes.array,
  success: PropTypes.bool,
  featuredBoat: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    adsData: get(state.app, 'data.adsData', {}),
    search: get(state.app, 'data.search', {}),
    seoContent: get(state.app, 'data.seoContent', []),
    privateFeaturedAds: get(state.app, 'data.privateFeaturedAds', undefined),
    blogSection: get(state.app, 'data.blogSection', undefined),
    featuredBoat: get(state.app, 'data.featuredBoat', []),
    isWorking: state.app.isWorking,
    componentWorking: state.app.componentWorking,
    success: state.app.success,
    errors: state.app.errors,
    message: state.app.message,
    statusCode: state.app.statusCode
  };
};

// we create a function that will run only once bypassing multiple renders.
const justFirstTime = runOnce();

const fetchData = (dispatch, locationAndHistoryQueries, cookies, abTestContext) => {
  return dispatch(actions.getHomeData(locationAndHistoryQueries, cookies, abTestContext));
};

const loadHomePageData = async ({
                                  abTestContext,
                                  dispatch,
                                  history,
                                  location,
                                  cookies,
                                  debug = process.env.REACT_APP_LOCAL_DEBUG
                                }) => {
  const locationAndHistoryQueries = getParamsFromLocationAndHistoryQueryString({
    location,
    history
  });

  if (utils.isServer()) {
    return fetchData(dispatch, locationAndHistoryQueries, cookies?.cookies, abTestContext);
  }

  if (actions.shouldGetData(location)) {
    return fetchData(dispatch, locationAndHistoryQueries, cookies?.cookies, abTestContext);
  }

  if (justFirstTime() && debug) {
    return fetchData(dispatch, locationAndHistoryQueries, cookies?.cookies, abTestContext);
  }
};

const HomePageWithDataLoaded = frontloadConnect(loadHomePageData, {
  onUpdate: true,
  onMount: true
})(Home);
const HomePageWithRedux = connect(
  mapStateToProps,
  null
)(HomePageWithDataLoaded);
const HomePageWithCookies = withCookies(HomePageWithRedux);
const HomePageWithABTest = withABTestContext(HomePageWithCookies);

export default HomePageWithABTest;
