import React from 'react';
import QuickSearchFilters from './QuickSearchFilters';
import PropTypes from 'prop-types';
import { AdDisplayer } from '../Ads/AdDisplayer/AdDisplayer';

const QuickSearchLayout = ({
  overlapLayout,
  messages,
  values,
  filterValues,
  layoutFlags,
  actions,
  adParams,
  box1Ad
}) => {
  const filterProps = { messages, values, filterValues, layoutFlags, actions };
  if (overlapLayout) {
    return <QuickSearchFilters {...filterProps} />;
  }
  const box1AdOb = box1Ad ? { 'box-1': box1Ad } : null;
  return (
    <div className="qs-content-body">
      <div className="qs-block qs-content-element">
        <div id="qs-filters" className="qs-filters">
          <QuickSearchFilters layoutFlags={layoutFlags} {...filterProps} />
        </div>
        <div className="qs-ad-flex">
          <div className="qs-ad-content">
            <AdDisplayer
              newAdsConfig={box1AdOb}
              adSlot={'box-1'}
              googleAdParams={adParams.box1Params} />
          </div>
        </div>
      </div>
    </div>
  );
};

QuickSearchLayout.propTypes = {
  messages: PropTypes.object,
  actions: PropTypes.object,
  values: PropTypes.object,
  filterValues: PropTypes.object,
  layoutFlags: PropTypes.object,
  overlapLayout: PropTypes.bool,
  adParams: PropTypes.object,
  box1Ad: PropTypes.object
};

export default QuickSearchLayout;
